<template>
  <div class="row-wrapper">
    <div class="row">
      <h4 class="title">{{ title }}</h4>
      <input @input="input" class="input" :placeholder="placeholder" />
    </div>
    <button
      :disabled="loading"
      :class="[loading ? 'btn-disabled' : 'btn-enabled']"
      @click="verify"
    >
      {{ btnText }}
    </button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "VerifyCodeInputLine",
  emits: ["input"],
  props: {
    mobile: String,
    title: String,
    placeholder: String,
    url: String,
  },
  data() {
    return {
      btnText: "获取验证码",
      seconds: 0,
      intervalId: 0,
      loading: false,
    };
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.value);
    },
    reset() {
      this.intervalId = 0;
      this.loading = false;
      this.btnText = "获取验证码";
    },
    countDown() {
      this.seconds = this.seconds - 1;
      this.btnText = `${this.seconds}秒后重新获取`;
      if (this.seconds == 0) {
        clearInterval(this.intervalId);
        this.reset();
      }
    },
    verify() {
      if (this.mobile == "") {
        alert("请输入手机号");
        return;
      }
      if (this.seconds > 0) return;
      this.seconds = 30;
      this.loading = true;
      this.btnText = `${this.seconds}秒后重新获取`;
      this.intervalId = setInterval(this.countDown, 1000);
      axios
        .post(this.url)
        .then((response) => {
          if (response.data.code == 0) {
            alert("发送成功，请注意查看手机短信");
          } else {
            alert("发送失败： " + response.data.msg);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row-wrapper {
  flex-direction: row;
  display: flex;
  margin: 10px 40px 10px 40px;
}

.row {
  color: #bdff06;
  border-color: #bdff06;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  padding: 5px 0;
  margin-right: 10px;
  position: relative;
  width: 50%;
}

.title {
  position: absolute;
  top: -30px;
  left: 10px;
  background-color: #111111;
  z-index: 1;
  padding: 5px;
}

.input {
  width: 78%;
  border: none;
  background-color: #111111;
  color: #ffffff;
  line-height: 30px;
  outline: none;
}

.btn-enabled {
  background-color: #bdff06;
  padding: 5px;
  max-height: 45px;
  border-radius: 6px;
  flex: 1;
}

.btn-disabled {
  background-color: #444444;
  padding: 5px;
  max-height: 45px;
  border-radius: 6px;
  flex: 1;
  color: #999999;
}
</style>
