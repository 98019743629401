<template>
  <div style="text-align: center;">
    <h2 style="margin-bottom: 60px"></h2>
    <img
      style="margin-top: 20px"
      width="100"
      height="100"
      src="../assets/logo.png"
    />
    <div style="display: flex; flex: 1">
      <button class="btn-enabled" @click="download">点击下载APP</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DownloadView",
  components: {},
  data() {
    return {
      url: ''
    };
  },
  beforeMount() {
    axios
        .post(process.env.VUE_APP_BASE_URL + "/app/download/get-latest-path")
        .then((response) => {
          if (response.data.code == 0) {
            this.url = response.data.data
          } else {
            alert("获取下载地址失败： " + response.data.msg);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
  },
  methods: {
    download() {
      window.location.href = this.url
    }
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-enabled {
  margin-top: 20px;
  background-color: #bdff06;
  padding: 10px 0;
  max-height: 45px;
  border-radius: 6px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}
</style>
