<template>
  <div style="text-align: center;">

    <img
      style="margin-top: 20px"
      width="100"
      height="100"
      src="../assets/logo.png"
    />
    <InputLine
      style="margin-top: 30px"
      title="账号"
      placeholder="请输入手机号"
      @input="inputUsername"
    />
    <InputLine
      style="margin-top: 30px"
      title="昵称"
      placeholder="请输入昵称"
      @input="inputnickname"
    />
    <VerifyCodeInputLine
      style="margin-top: 30px"
      :mobile="username"
      title="验证码"
      placeholder="请输入验证码"
      :url="verifyCodeUrl"
      @input="inputVerifyCode"
    />
    <InputLine
      style="margin-top: 30px"
      title="密码"
      placeholder="请输入密码"
      :password="true"
      @input="inputPassword"
    />
    <InputLine
      style="margin-top: 30px"
      title="确认密码"
      placeholder="请再次输入密码"
      :password="true"
      @input="inputConfirmPassword"
    />
    <div style="display: flex; flex: 1">
      <button
        :disabled="disabled"
        @click="register"
        :class="[disabled ? 'btn-disabled' : 'btn-enabled']"
      >
        注册
      </button>
    </div>
    <h3
      style="margin-top: 10px; color: #bdff06; text-decoration: underline"
      @click="goToDownload"
    >
      已注册？前往下载
    </h3>
  </div>
</template>

<script>
import axios from "axios";
import InputLine from "../components/InputLine.vue";
import VerifyCodeInputLine from "../components/VerifyCodeInputLine.vue";
export default {
  name: "RegistrationView",
  components: {
    InputLine,
    VerifyCodeInputLine,
  },
  data() {
    return {
      username: "",
      nickname: "",
      code: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    inputVerifyCode(value) {
      this.code = value;
    },
    inputUsername(value) {
      this.username = value;
    },
    inputnickname(value) {
      this.nickname = value;
    },
    inputPassword(value) {
      this.password = value;
    },
    inputConfirmPassword(value) {
      this.confirmPassword = value;
    },
    register() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/registration/register", {
          pid: parseInt(this.$route.params.pid),
          platform: this.$route.query.platform,
          username: this.username,
          nickname: this.nickname,
          code: this.code,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        .then((response) => {
          if (response.data.code == 0) {
            alert("注册成功");
            this.goToDownload();
          } else {
            alert("注册失败： " + response.data.msg);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToDownload() {
      this.$router.push("/download");
    },
  },
  computed: {
    verifyCodeUrl() {
      return process.env.VUE_APP_BASE_URL + `/user/registration/send-register-code/${this.username}`;
    },
    disabled() {
      return (
        this.username.trim() == "" ||
        this.nickname.trim() == "" ||
        this.code.trim() == "" ||
        this.password.trim() == "" ||
        this.confirmPassword.trim() == ""
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-enabled {
  margin-top: 20px;
  background-color: #bdff06;
  padding: 10px 0;
  max-height: 45px;
  border-radius: 6px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}

.btn-disabled {
  margin-top: 20px;
  background-color: #444444;
  padding: 10px 0;
  max-height: 45px;
  border-radius: 6px;
  color: #999999;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}
</style>
