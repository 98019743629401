<template>
  <div class="row">
    <h4 class="title">{{ title }}</h4>
    <input
      class="input"
      @input="input"
      :type="password ? 'password' : 'text'"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: "InputLine",
  emits: ["input"],
  props: {
    title: String,
    placeholder: String,
    password: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  color: #bdff06;
  border-color: #bdff06;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  padding: 5px 0;
  margin: 10px 40px;
  position: relative;
}

.title {
  position: absolute;
  top: -30px;
  left: 10px;
  background-color: #111111;
  z-index: 1;
  padding: 5px;
}

.input {
  width: 90%;
  border: none;
  background-color: #111111;
  color: #ffffff;
  line-height: 30px;
  outline: none;
}
</style>
