<template>
  <div v-html="content">
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "InnerMessageView",
  components: {},
  data() {
    return {
      content: "",
    };
  },
  beforeMount() {
    axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/inner-message/load-content/" +
          this.$route.params.id
      )
      .then((response) => {
        if (response.data.code == 0) {
          this.content = response.data.data;
        } else {
          alert("获取消息内容失败： " + response.data.msg);
        }
      })
      .catch(function (error) {
        // 请求失败处理
        alert(error);
      });
  },
  methods: {},
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
